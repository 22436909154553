<template>
    <div class="plan-page">
        <div class="plan-panel">
            <div class="bg"></div>
            <swiper class="swiper" :options="swiperOption" ref="mySwiper">
				<swiper-slide>
					<div class="sssh">
                        <div class="l1">
                            <div class="p11" @click="box.p11=true"></div>
                            <div class="p12" @click="box.p12=true"></div>
                            <div class="p13" @click="box.p13=true"></div>
                            <div class="p14" @click="box.p14=true"></div>
                            <div class="p15" @click="box.p15=true"></div>
                            <div class="p16" @click="box.p16=true"></div>
                            <div class="p17" @click="box.p17=true"></div>
                            <div class="p18" @click="box.p12=true"></div>
                        </div>
                        <div class="l2"></div>
                    </div>
				</swiper-slide>
                <swiper-slide>
					<div class="yjpj">
                        <div class="l1">
                            <div class="p21" @click="box.p21=true"></div>
                            <div class="p22" @click="box.p22=true"></div>
                            <div class="p23" @click="box.p23=true"></div>
                            <div class="p24" @click="box.p24=true"></div>
                            <div class="p25" @click="box.p25=true"></div>
                            <div class="p26" @click="box.p26=true"></div>
                            <div class="p27" @click="box.p27=true"></div>
                        </div>
                        <div class="l2">
                            <div class="p18" @click="box.p12=true"></div>
                            <div class="p17" @click="box.p17=true"></div>
                            <div class="p13" @click="box.p13=true"></div>
                        </div>
                    </div>
				</swiper-slide>
                <!-- <swiper-slide>
					<div class="znss">
                        <div class="l1"></div>
                        <div class="l2"></div>
                    </div>
				</swiper-slide> -->
                <swiper-slide>
					<div class="yqdx">
                        <div class="l1"></div>
                        <div class="l2"></div>
                    </div>
				</swiper-slide>
                <!-- <swiper-slide>
					<div class="xfsj">
                        <div class="l1"></div>
                        <div class="l2"></div>
                    </div>
				</swiper-slide> -->
            </swiper>
        </div>
        <div class="bottom-menu">
            <a class="item" :class="{ active : plan== 0 }" @click="toSlide(0)" href="javascript:;">舒适生活</a>
            <a class="item" :class="{ active : plan== 1 }" @click="toSlide(1)" href="javascript:;">意境品质</a>
            <!-- <a class="item" :class="{ active : plan== 2 }" @click="toSlide(2)" href="javascript:;">智能设施</a> -->
            <a class="item" :class="{ active : plan== 2 }" @click="toSlide(2)" href="javascript:;">园区动线</a>
            <!-- <a class="item" :class="{ active : plan== 4 }" @click="toSlide(4)" href="javascript:;">消防设计</a> -->
        </div>
        <div class="guide" v-if="guide" @click="hideGuide"></div>

        <div class="box-panel" v-if="box.p11">
			<div class="bg p11">
				<a href="javascript:;" @click="box.p11=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
        <div class="box-panel" v-if="box.p12">
			<div class="bg p12">
				<a href="javascript:;" @click="box.p12=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
        <div class="box-panel" v-if="box.p13">
			<div class="bg p13">
				<a href="javascript:;" @click="box.p13=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
        <div class="box-panel" v-if="box.p14">
			<div class="bg p14">
				<a href="javascript:;" @click="box.p14=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
        <div class="box-panel" v-if="box.p15">
			<div class="bg p15">
				<a href="javascript:;" @click="box.p15=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
        <div class="box-panel" v-if="box.p16">
			<div class="bg p16">
				<a href="javascript:;" @click="box.p16=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
        <div class="box-panel" v-if="box.p17">
			<div class="bg p17">
				<a href="javascript:;" @click="box.p17=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>

        <div class="box-panel" v-if="box.p21">
			<div class="bg p21">
				<a href="javascript:;" @click="box.p21=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
        <div class="box-panel" v-if="box.p22">
			<div class="bg p22">
				<a href="javascript:;" @click="box.p22=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
        <div class="box-panel" v-if="box.p23">
			<div class="bg p23">
				<a href="javascript:;" @click="box.p23=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
        <div class="box-panel" v-if="box.p24">
			<div class="bg p24">
				<a href="javascript:;" @click="box.p24=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
        <div class="box-panel" v-if="box.p25">
			<div class="bg p25">
				<a href="javascript:;" @click="box.p25=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
        <div class="box-panel" v-if="box.p26">
			<div class="bg p26">
				<a href="javascript:;" @click="box.p26=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>
        <div class="box-panel" v-if="box.p27">
			<div class="bg p27">
				<a href="javascript:;" @click="box.p27=false"><i class="iconfont icon-close"></i></a>
			</div>
		</div>

    </div>
</template>
<script>
import { mapState } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper.scss'

export default {
    name: 'Plan',
    data (){
		return{
            plan: 0,
            guide: false,
            box:{
				p11: false,
				p12: false,
				p13: false,
				p14: false,
				p15: false,
				p16: false,
				p17: false,
				p21: false,
				p22: false,
				p23: false,
				p24: false,
				p25: false,
				p26: false,
				p27: false,
			},
			swiperOption: {
				on: {
					slideChange: (swiper) => {
						this.plan = swiper.activeIndex
					}
				}
			}
		}
	},
	components: {
		Swiper,
		SwiperSlide
	},
	computed: mapState([
        'openMenu'
    ]),
    mounted(){
        this.guide = localStorage.getItem('plan_guide') ? false : true
    },
	methods:{
		toSlide(index) {
            this.plan = index
            this.$refs.mySwiper.$swiper.slideTo(index, 300)
        },
        hideGuide(){
            this.guide = false
            localStorage.setItem('plan_guide',true)
        }
	}
}
</script>
<style lang="scss">
@import "@/assets/styles/common.scss";
.plan-page{
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    .plan-panel{
        position: relative;
        top: 50px;
        .bg{
            position: absolute;
            @include bg('plan/bg.png',375px,355px);
        }
        .l2{
            position: relative;
            // top: 10px;
            margin-top: 30px;
        }
        .sssh{
            .l1{
                position: relative;
                @include bg('plan/p1_1.png',375px,355px);

                .p11{
                    position: absolute;
                    top: 265px;
                    left: 218px;
                    width: 48px;
                    height: 22px;
                }
                .p12{
                    position: absolute;
                    top: 208px;
                    left: 220px;
                    width: 46px;
                    height: 20px;
                }
                .p13{
                    position: absolute;
                    top: 198px;
                    left: 175px;
                    width: 25px;
                    height: 58px;
                }
                .p14{
                    position: absolute;
                    top: 128px;
                    left: 168px;
                    width: 44px;
                    height: 20px;
                }
                .p15{
                    position: absolute;
                    top: 206px;
                    left: 111px;
                    width: 46px;
                    height: 20px;
                }
                .p16{
                    position: absolute;
                    top: 150px;
                    left: 220px;
                    width: 46px;
                    height: 20px;
                }
                .p17{
                    position: absolute;
                    top: 110px;
                    left: 115px;
                    width: 46px;
                    height: 20px;
                }
                .p18{
                    position: absolute;
                    top: 145px;
                    left: 110px;
                    width: 51px;
                    height: 23px;
                }
            }
            .l2{
                @include bg('plan/p1_2.png',375px,130px);
            }
        }
        .yjpj{
            .l1{
                position: relative;
                @include bg('plan/p2_1.png',375px,355px);

                .p21{
                    position: absolute;
                    top: 100px;
                    left: 98px;
                    width: 66px;
                    height: 25px;
                }
                .p22{
                    position: absolute;
                    top: 158px;
                    left: 198px;
                    width: 66px;
                    height: 25px;
                }
                .p23{
                    position: absolute;
                    top: 130px;
                    left: 140px;
                    width: 66px;
                    height: 25px;
                }
                .p24{
                    position: absolute;
                    top: 192px;
                    left: 135px;
                    width: 69px;
                    height: 33px;
                }
                .p25{
                    position: absolute;
                    top: 160px;
                    left: 95px;
                    width: 70px;
                    height: 21px;
                }
                .p26{
                    position: absolute;
                    top: 228px;
                    left: 198px;
                    width: 66px;
                    height: 25px;
                }
                .p27{
                    position: absolute;
                    top: 270px;
                    left: 198px;
                    width: 66px;
                    height: 25px;
                }


            }
            .l2{
                @include bg('plan/p2_2.png',375px,130px);

                .p18{
                    position: absolute;
                    top: 50px;
                    left: 35px;
                    width: 74px;
                    height: 40px;
                }
                .p17{
                    position: absolute;
                    top: 50px;
                    left: 148px;
                    width: 74px;
                    height: 40px;
                }
                .p13{
                    position: absolute;
                    top: 50px;
                    left: 262px;
                    width: 74px;
                    height: 40px;
                }
            }
        }
        .znss{
            .l1{
                @include bg('plan/p3_1.png',375px,355px);
            }
            .l2{
                @include bg('plan/p3_2.png',375px,130px);
            }
        }
        .yqdx{
            .l1{
                @include bg('plan/p5_1.png',375px,355px);
            }
            .l2{
                @include bg('plan/p5_2.png',375px,130px);
            }
        }
        .xfsj{
            .l1{
                @include bg('plan/p5_1.png',375px,355px);
            }
            .l2{
                @include bg('plan/p5_2.png',375px,130px);
            }
        }
    }
    
    .bottom-menu{
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 80px;

        .item{
            color: rgba(255,255,255,.5);
            font-size: 10px;
            // background: rgba(164,134,104,.5);
            padding: 4px 8px;
            border: 1px solid rgba(255,255,255,.3);
            border-radius: 3px;

            &.active{
                color: #fff;
                background: rgba(164,134,104,.5);
                border: 1px solid rgba(255,232,210,.5);
            }
        }
    }

    .box-panel{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background: rgba(0,0,0,0.8);
		z-index: 9999;
		.bg{
			position: absolute;
			width: 327px;
			height: 405px;
			top: 10vh;
			left: 23px;
			a{
				position: absolute;
				top: 10px;
				right: 10px;
				width: 27px;
				height: 27px;
				background: rgba(0,0,0,0.6);
				border-radius: 50%;
				text-align: center;
				line-height: 27px;
				.iconfont{
					color: #fff;
				}
			}
		}
		.p11{
			@include bg('plan/p11.png',327px,405px);
		}
        .p12{
			@include bg('plan/p12.png',327px,405px);
		}
        .p13{
			@include bg('plan/p13.png',327px,405px);
		}
        .p14{
			@include bg('plan/p14.png',327px,405px);
		}
        .p15{
			@include bg('plan/p15.png',327px,405px);
		}
        .p16{
			@include bg('plan/p16.png',327px,405px);
		}
        .p17{
			@include bg('plan/p17.png',327px,405px);
		}

        .p21{
			@include bg('plan/p21.png',327px,405px);
		}
        .p22{
			@include bg('plan/p22.png',327px,405px);
		}
        .p23{
			@include bg('plan/p23.png',327px,405px);
		}
        .p24{
			@include bg('plan/p24.png',327px,405px);
		}
        .p25{
			@include bg('plan/p25.png',327px,405px);
		}
        .p26{
			@include bg('plan/p26.png',327px,405px);
		}
        .p27{
			@include bg('plan/p27.png',327px,405px);
		}
	}
}
@media screen and (max-height: 603px) {
    .plan-page{
        .plan-panel{
            top: -10px;
            .bg{
                // transform: scale(.9);
            }
            .l1{
                top: 0;
                // transform: scale(.9);
            }
            .l2{
                // top: -20px;
                margin-top: 10px;
            }
        }
    }
}
</style>
